import React from "react"
import News from "../../../blocks/en/news_page"
import { graphql } from "gatsby"

export const pageQuery = graphql`
query {
  news: newsYaml(slug: { eq: "saveljevs_sorokins_in_national_team" }) {
    ...NewsInfoEn
  }
}`

export default class NewsTextEn extends News {
  renderNews() {
    return (
      <div className="row">
        <div className="col-xl-12">
          <p>Midfielder Mantova AC 1911 Aleksejs Saveljevs and defender FC RFS (Riga) Vladislavs Sorokins was called up to the Latvian National Team. The Latvian National Team is scheduled to play one friendly game with the San Marino National Team and two official games with the Faroe Islands and the Andorra Nationals Teams.</p>
        </div>
      </div>
    )
  }
}